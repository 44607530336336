const noImageUrl = './images/icons/noimage.png';
const LOT_IMG_SIZES = {
	thumb: 'thumb/',
	full: '',
};


/**
 * Преобразует специальные символы в HTML-сущности
 * @param {string} text
 * @returns {string}
 */
function htmlspecialchars(text) {
	if ((typeof text) !== 'string' || text == null || text === 'null') return '';
	const map = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		'\'': '&#039;',
	};

	return text.replace(/[&<>"']/g, m => map[m]);
}

/**
 * Преобразует специальные HTML-сущности обратно в соответствующие символы
 * @param {string} encodedString
 * @returns {string}
 */
function htmlspecialcharsDecode(encodedString) {
	const textArea = document.createElement('textarea');
	textArea.innerHTML = encodedString;
	return textArea.value;
}

/**
 *  Возвращает полную ссылку на лот исходя из его относительного пути и типа
 * @param id
 * @param type
 * @returns {string}
 * @private
 */
function lotImageURLBuilder(id, type) {
	return `./images/lots/${LOT_IMG_SIZES[type]}${id}`;
}

/**
 * Возвращает ссылку на картинку imageId лота lot
 * @param lot объект лота
 * @param imageId ид картинки, ссылку на которую нужно получить
 * @param type тип требуемой картинки, описвны в LOT_IMG_SIZES
 * @param returnStubImage если false, вместо загрушки вернёт false
 * @returns {string}
 * @throws Error Если в переданном объекте нет свойства curImg
 */
function getLotImageUrl(lot, imageId, type, returnStubImage = true) {
	const isImageExist = (imageId === undefined || lot.lot_img == null);
	if (!isImageExist) return returnStubImage ? noImageUrl : false;
	const url = htmlspecialchars(lot.lot_img[imageId]);
	return (url ? lotImageURLBuilder(url, type) : returnStubImage) ? noImageUrl : false;
}

/**
 * Возвращает ссылку на главную картинку лота
 * @param lot объект лота
 * @param type тип требуемой картинки, описвны в LOT_IMG_SIZES
 * @param returnStubImage если false, вместо загрушки вернёт false
 * @returns {string}
 * @throws Error Если в переданном объекте нет свойства curImg
 */
function getLotMainImageUrl(lot, type, returnStubImage = true) {
	if (!lot.hasOwnProperty('curImg')) {
		throw new Error('В объекте не найдены поле curImg');
	}
	return getLotImageUrl(lot, lot.curImg, type, returnStubImage);
}

/**
 * Добавляет параметр в URL.
 * @param url {string} к какому URL добавить
 * @param parameterName {string}
 * @param parameterValue {string}
 * @param atStart {boolean} принужительно добавить первым, даже если параметры в url уже есть
 * @returns {string}
 */
function addParameter(url, parameterName, parameterValue, atStart = false) {
	const replaceDuplicates = true;
	let cl = 0;
	let urlhash = '';
	if (url.indexOf('#') > 0) {
		cl = url.indexOf('#');
		urlhash = url.substring(url.indexOf('#'), url.length);
	} else {
		urlhash = '';
		cl = url.length;
	}
	const sourceUrl = url.substring(0, cl);
	const urlParts = sourceUrl.split('?');
	let newQueryString = '';

	if (urlParts.length > 1) {
		const parameters = urlParts[1].split('&');
		for (let i = 0; (i < parameters.length); i++) {
			const parameterParts = parameters[i].split('=');
			if (!(replaceDuplicates && parameterParts[0] === parameterName)) {
				if (newQueryString === '') {
					newQueryString = '?';
				} else {
					newQueryString += '&';
				}
				newQueryString += `${parameterParts[0]}=${parameterParts[1] ? parameterParts[1] : ''}`;
			}
		}
	}
	if (newQueryString === '') newQueryString = '?';

	if (atStart) {
		newQueryString = `?${parameterName}=${parameterValue}${newQueryString.length > 1 ? `&${newQueryString.substring(1)}` : ''}`;
	} else {
		if (newQueryString !== '' && newQueryString !== '?') newQueryString += '&';
		newQueryString += `${parameterName}=${parameterValue || ''}`;
	}
	return urlParts[0] + newQueryString + urlhash;
}

function makeCatUrl(catalogId) {
	return `/cat/${catalogId}${SID}`; // eslint-disable-line no-undef
}

function makeLotURL(ignored, lotId) {
	return `/item/${lotId}`;
}

const isMobile = () => ('ontouchstart' in document.documentElement);

window.make_cat_url = makeCatUrl;
export {
	getLotMainImageUrl,
	getLotImageUrl,
	htmlspecialchars,
	htmlspecialcharsDecode,
	addParameter,
	makeCatUrl,
	makeLotURL,
	isMobile
};
